











import { Component, Vue } from 'vue-property-decorator';
import SignupWizard from '@/components/SignupWizard.vue';

@Component({ components: { SignupWizard } })
export default class SignupMain extends Vue {}
