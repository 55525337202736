






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ISearchResult } from '@/scripts/models';
import ProfileCard from '@/components/ProfileCard.vue';

@Component({ components: { ProfileCard } })
export default class SearchResults extends Vue {
  @Prop({ required: true, default: [] })
  private searchResults!: ISearchResult[];

  private showProfileCard = false;
  private page = 1;
  private itemsPerPage = 4;

  // Reset page pointer when new data arrives
  @Watch('searchResults', { deep: true })
  private resetPage(): void {
    this.page = 1;
  }
  // Open profile card when clicked
  private async clicked(searchId: string, isPremium: boolean): Promise<void> {
    await (this.$refs.profileCard as ProfileCard).showProfileCard(searchId, isPremium);
  }
  // Calculate number of pages
  private numberOfPages(): number {
    return Math.ceil(this.searchResults.length / this.itemsPerPage);
  }
  // Next page navigate
  private nextPage(): void {
    if (this.page + 1 <= this.numberOfPages()) this.page += 1;
  }
  // Previous page navigate
  private previousPage(): void {
    if (this.page - 1 >= 1) {
      this.page -= 1;
    }
  }
  private randomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
