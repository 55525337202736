






















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IRegion, ICity, ICategory, ICompetency, ISearchResult } from '@/scripts/models';
import SearchResults from '@/components/SearchResults.vue';
import { ApiCommon } from '@/scripts/apiCommon.ts';
import { ApiProfile } from '@/scripts/apiProfile.ts';
import * as lib from '@/scripts/apiHelper.ts';

@Component({ components: { SearchResults } })
export default class Search extends Vue {
  public regions: IRegion[] = [];
  public region: IRegion = { RegionId: 0, RegionName: '' };
  public regionErrorMessage = '';

  public cities: ICity[] = [];
  public city: ICity = { CityId: 0, ZipCode: '', CityName: '' };
  public cityErrorMessage = '';

  public categories: ICategory[] = [];
  public category: ICategory = { CategoryId: 0, CategoryName: '' };
  public categoryErrorMessage = '';

  public competencies: ICompetency[] = [];
  public competency: ICompetency = { CompetencyId: 0, CompetencyName: '' };
  public competencyErrorMessage = '';

  public searchResults: ISearchResult[] = [];

  private snackbarShow = false;

  private async mounted(): Promise<void> {
    try {
      this.categories = await ApiCommon.getCategories();
    } catch (error) {
      this.categoryErrorMessage = lib.getErrorMessage(error);
    }
    try {
      this.regions = await ApiCommon.getRegions();
    } catch (error) {
      this.regionErrorMessage = lib.getErrorMessage(error);
    }
  }

  @Watch('category')
  private async changeCategory(value: ICategory): Promise<void> {
    try {
      if (value.CategoryId && value.CategoryId > 0) {
        this.competencies = await ApiCommon.getCompetencies(value.CategoryId);
      }
    } catch (error) {
      this.competencyErrorMessage = lib.getErrorMessage(error);
    }
  }

  @Watch('region')
  private async changeRegion(value: IRegion): Promise<void> {
    try {
      if (value.RegionId && value.RegionId > 0) {
        this.cities = await ApiCommon.getCities(value.RegionId);
      }
    } catch (error) {
      this.cityErrorMessage = lib.getErrorMessage(error);
    }
  }

  public resetSearch(): void {
    this.category = { CategoryId: 0, CategoryName: '' };
    this.competency = { CompetencyId: 0, CompetencyName: '' };
    this.competencies = [];
    this.region = { RegionId: 0, RegionName: '' };
    this.city = { CityId: 0, ZipCode: '', CityName: '' };
    this.cities = [];
    this.searchResults = [];
  }

  public async search(): Promise<void> {
    if (
      this.category.CategoryId > 0 ||
      this.competency.CompetencyId > 0 ||
      this.region.RegionId > 0 ||
      this.city.CityId > 0
    ) {
      this.searchResults = [];
      this.searchResults = await ApiProfile.profilesFiltered(
        this.category.CategoryId,
        this.competency.CompetencyId,
        this.region.RegionId,
        this.city.CityId
      );
    } else {
      this.snackbarShow = true;
    }
  }
}
