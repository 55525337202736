







import { Component, Vue } from 'vue-property-decorator';
import Search from '@/components/Search.vue';
import SignupMain from '@/components/SignupMain.vue';

@Component({ components: { Search, SignupMain } })
export default class Home extends Vue {}
