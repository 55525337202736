
















































































































import { Component, Vue } from 'vue-property-decorator';
import { IProfileCardResult, IProfileCompetenciesResult, IAsset } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';
import { ApiCommon } from '@/scripts/apiCommon';
import { ApiAsset } from '@/scripts/apiAsset';

@Component({})
export default class ProfileCard extends Vue {
  private cardData: IProfileCardResult = {} as IProfileCardResult;
  private competencies: IProfileCompetenciesResult[] = [];
  private profileImages: IAsset[] = [];

  private profileCardVisible = false;
  private isPremium = false;

  // Show profile card with updated data
  public async showProfileCard(searchId: string, isPremium: boolean): Promise<void> {
    this.isPremium = isPremium;
    this.profileCardVisible = true;
    await ApiProfile.visit({ SearchId: searchId });
    this.cardData = await ApiProfile.profileCardData(searchId);
    this.competencies = await ApiCommon.getCompetenciesBySearchId(searchId);
    this.profileImages = await ApiAsset.getAssetsBySearchIdandType(searchId, 'IMAGE');

    // Add profile picture to caroussel
    if (!this.profileImages) {
      this.profileImages = [
        { AssetPath: this.cardData.AvatarImage || '/static/NoLogo' + this.randomInteger(1, 4) + '.jpg' } as IAsset
      ];
    } else {
      this.profileImages.unshift({
        AssetPath: this.cardData.AvatarImage || '/static/NoLogo' + this.randomInteger(1, 4) + '.jpg'
      } as IAsset);
    }
  }

  private randomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
